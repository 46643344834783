import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import './App.css';

function App() {
  // State to manage the display of content
  const [showAboutMe, setShowAboutMe] = useState(false);

  // Function to toggle the content
  const toggleAboutMe = () => {
    setShowAboutMe(!showAboutMe);
  };

  return (
    <div className="background">
      <Helmet>
        <title>Thibaut GENET</title>
      </Helmet>
      <div className="container glass">
        <div className={`main-content ${showAboutMe ? 'hidden' : ''}`}>
          <header>
            <img src="/assets/images/thibaut.jpg" alt="Thibaut GENET" className="profile-pic"/>
            <h1>Thibaut GENET</h1>
            <p>Étudiant en Bachelor Cybersécurité & Ethical Hacking à l'<a target='_blank' href="https://efrei.fr">Efrei</a></p>
            <div className="buttons">
              <a href="mailto:thibaut@genet.cloud" target="_blank" rel="noopener noreferrer" className="btn btn-mail">
                <i className="fas fa-envelope"></i> Mail
              </a>
              <a href="https://www.linkedin.com/in/thibautgenet" target="_blank" rel="noopener noreferrer" className="btn btn-linkedin">
                <i className="fab fa-linkedin"></i> LinkedIn
              </a>
              <a href="https://github.com/thibautgnt" target="_blank" rel="noopener noreferrer" className="btn btn-github">
                <i className="fab fa-github"></i> GitHub
              </a>
            </div>
          </header>
          <footer>
          <span onClick={toggleAboutMe} className="about-me-link">
          À propos <i className="fa fa-arrow-right"></i>
</span>

          </footer>
        </div>

        <div className={`about-me-content ${showAboutMe ? '' : 'hidden'}`}>
          {/* Waiting page content */}
          <div className="waiting-content">
            <h2>À venir ...</h2>
          </div>
          <span onClick={toggleAboutMe} className="return-link">
          <i className="fa fa-arrow-left"></i>  Retour 
</span>
        </div>
      </div>
    </div>
  );
}

export default App;
